import { types } from 'mobx-state-tree';
import { ReviewCategories } from './ReviewCategories';

export const ReviewBest = types.model('ReviewBest', {
  hotel_id: types.maybeNull(types.string),
  user_name: types.maybeNull(types.string),
  rating: types.maybeNull(types.number),
  provider: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  language_original: types.maybeNull(types.string),
  categories: types.optional(types.array(ReviewCategories), [])
});
