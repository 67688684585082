import _groupBy from 'lodash/groupBy';
import _minBy from 'lodash/minBy';

function roomTypeMapper(room_type) {
  const filteredTariffs = room_type.tariffs.filter(tariff => tariff.type !== 'tour');
  
  let tariffs = _groupBy(filteredTariffs, 'tariff_id');

  tariffs = Object.values(tariffs).map(group => {
    const t = _minBy(group, 'price.amount');
    return { ...t, id: t.tariff_id };
  });

  return { ...room_type, tariffs };
}

function responseMapper(response) {
  const { meta, data } = response;

  const room_types = data.room_types.map((rt) => roomTypeMapper(rt));

  return { meta, data: { ...data, room_types } };
}

export default responseMapper;
