import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { computed, observable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import _orderBy from 'lodash/orderBy';

import { CornerDown } from 'components/icons';

import Tariff from './Tariff';

const DropdownButton = styled.div`
  color: #3C6F9D;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  user-select: none;
  margin-top: 14px;
  cursor: pointer;
`;

const Wrapper = styled.div`
  ${Tariff} + ${Tariff} {
    margin-top: 15px;
  }

  ${CornerDown} {
    font-size: 6px;
    margin-left: 6px;

    path {
       fill: #3C6F9D;
    }
  }

  ${({ opened }) => opened && css`
    ${CornerDown} {
      transform: rotate(180deg);
    }
  `}
`;

@withTranslation()
@inject('bookingState', 'commissionVisibility')
@observer
class Tariffs extends React.Component {
  @observable opened = false;

  static propTypes = {
    className: PropTypes.string,
    hotel: PropTypes.object.isRequired,
    notAvailableTariffs: PropTypes.bool,
    room_type: PropTypes.object.isRequired,
    onSelect: PropTypes.func,
    bookingState: PropTypes.object.isRequired,
    commissionVisibility: PropTypes.object.isRequired,
    t: PropTypes.func
  }

  static defaultProps = {
    className: ''
  }

  @computed get hasNotAvailableTariff() {
    const { room_type: { tariffs } } = this.props;
    const filteredTariffs = tariffs.filter(tariff => tariff.type !== 'tour');
    return !!filteredTariffs.find(t => !t.is_available);
  }

  @computed get tariffs() {
    const { room_type: { tariffs }, notAvailableTariffs } = this.props;
    const filteredTariffs = tariffs.filter(tariff => tariff.type !== 'tour');
    
    if (notAvailableTariffs || !this.hasNotAvailableTariff || this.opened) {
      return _orderBy(filteredTariffs, ['is_available'], 'desc');
    }

    return filteredTariffs.filter(t => t.is_available);
  }

  @computed get withCommission() {
    const { commissionVisibility } = this.props;
    return commissionVisibility.isAmountVisible;
  }

  @computed get withPercentCommission() {
    const { commissionVisibility } = this.props;
    return commissionVisibility.isPercentVisible;
  }

  @action
  handleToggle = () => {
    this.opened = !this.opened;
  }

  handleSelect = (availability) => {
    const { bookingState } = this.props;
    bookingState.toggleAvailability({ availability });
  }

  render() {
    const { hotel, room_type, notAvailableTariffs, t, ...rest } = this.props;

    const listItems = this.tariffs.map(tariff => (
      <Tariff
        key={tariff.id}
        hotel={hotel}
        room_type={room_type}
        tariff={tariff}
        onClick={this.handleSelect}
        withPercentCommission={this.withPercentCommission}
        withCommission={this.withCommission}
      />
    ));

    return (
      <Wrapper
        {...rest}
        opened={this.opened}
      >
        {listItems}

        {!notAvailableTariffs && this.hasNotAvailableTariff && (
          <DropdownButton
            onClick={this.handleToggle}
          >
            {this.opened
              ? t('Order.RoomType.DropdownAction.Opened')
              : t('Order.RoomType.DropdownAction.Closed')
            }

            <CornerDown />
          </DropdownButton>
        )}
      </Wrapper>
    );
  }
}

export default styled(Tariffs)``;
